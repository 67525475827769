import styled from "@emotion/styled";

export const PreviewFrame = styled.div`
    position: sticky;
    top: 20px;
    background:#D9D9D9;
    padding-left:30px;
    padding-right:30px;
    padding-bottom:30px;
`

