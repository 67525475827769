const inititalValue = [
    {
        type: "paragaph",
        children: [{ text: "" }]
    }
]

// const initialState = [
//     {
//         name: "",
//         emailId: 1,
//         image: "",
//         subjectLine: inititalValue,
//         preview: inititalValue,
//         serviceDesc: inititalValue,
//         beneficiaryDesc: inititalValue,
//         mainText: inititalValue,
//         slug: "",
//         ref: "",
//         uniqueID: "",
//         emailSubject: ""
//     },
//     {
//         name: "",
//         image: "",
//         emailId: 2,
//         subjectLine: inititalValue,
//         preview: inititalValue,
//         impactStat: [],
//         donationFor: inititalValue,
//         donationGoesFor: inititalValue,
//         mainText: inititalValue,
//         slug: "",
//         ref: "",
//         uniqueID: "",
//         emailSubject: ""
//     },
//     {
//         name: "",
//         image: "",
//         emailId: 3,
//         subjectLine: inititalValue,
//         preview: inititalValue,
//         beneficiaryName: inititalValue,
//         beneficiaryHelped: inititalValue,
//         beneficiaryAfter: inititalValue,
//         beneficiaryBefore: inititalValue,
//         mainText: inititalValue,
//         slug: "",
//         ref: "",
//         uniqueID: "",
//         emailSubject: ""
//     },
//     {
//         name: "",
//         image: "",
//         emailId: 4,
//         subjectLine: inititalValue,
//         preview: inititalValue,
//         socialMediaBenefit: [],
//         social: {
//             facebookLink: "",
//             instagramLink: "",
//         },
//         mainText: inititalValue,
//         slug: "",
//         ref: "",
//         uniqueID: "",
//         emailSubject: ""
//     },
//     {
//         name: "",
//         image: "",
//         emailId: 5,
//         subjectLine: inititalValue,
//         serviceDesc: inititalValue,
//         preview: inititalValue,
//         impactStat: [],
//         donationDoes: inititalValue,
//         mainGoalSummary: inititalValue,
//         mainText: inititalValue,
//         slug: "",
//         ref: "",
//         uniqueID: "",
//         emailSubject: ""
//     }
// ]

const initialState = {
    master: {},
    template1: {},
    template2: {},
    template3: {},
    template4: {},
    template5: {},
}

export default initialState